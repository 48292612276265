import React, { useState } from 'react';
import { arrayOf, bool, func, node, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';

// Section components
import SectionArticle from './SectionArticle';
import SectionCarousel from './SectionCarousel';
import SectionColumns from './SectionColumns';
import SectionFeatures from './SectionFeatures';
import SectionHero from './SectionHero';
import Slider from 'react-slick';

// Styles
// Note: these contain
// - shared classes that are passed as defaultClasses
// - dark theme overrides
// TODO: alternatively, we could consider more in-place way of theming components
import css from './SectionBuilder.module.css';
import SectionFooter from './SectionFooter';
import { useDispatch, useSelector } from 'react-redux';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
import { AvatarMedium, Button, ListingCard } from '../../../components';
import { setActiveListing } from '../../SearchPage/SearchPage.duck';
import { FormattedMessage } from 'react-intl';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { popularCategories, popularSubCategories } from '../../../util/dataExtractor';
import SortBy from '../../SearchPage/SortBy/SortBy';
import { useConfiguration } from '../../../context/configurationContext';
import {
  searchParamsPicker,
  validFilterParams,
  validUrlQueryParamsFromProps,
} from '../../SearchPage/SearchPage.shared';
import { listingFields } from '../../../config/configListing';
import { isAnyFilterActive, isOriginInUse } from '../../../util/search';
import { CLOTHING, PHONES_ACCESSORIES, SEEN_ON_SOCIAL_MEDIA } from '../../../util/types';

import banner1 from '../../../assets/banner1.png';
import banner2 from '../../../assets/banner2.png';
import { fetchDiscoverListings } from '../../LandingPage/LandingPage.duck';
import TrendingListing from './TrendingListing';
import RecentlyAddedListings from './RecentlyAddedListings';
import PopularCategories from './PopularCategories';
import SeenOnSocialMediaListings from './SeenOnSocialMediaListings';
import UserCard from './UserCard';
import GetItBeforeGoneListings from './GetItBeforeGoneListings';
import GamesSection from './GamesSection';
import CalenderSection from './CalenderSection';

const FILTER_DROPDOWN_OFFSET = -14;
// These are shared classes.
// Use these to have consistent styles between different section components
// E.g. share the same title styles
const DEFAULT_CLASSES = {
  sectionDetails: css.sectionDetails,
  title: css.title,
  description: css.description,
  ctaButton: css.ctaButton,
  blockContainer: css.blockContainer,
};

/////////////////////////////////////////////
// Mapping of section types and components //
/////////////////////////////////////////////

const defaultSectionComponents = {
  article: { component: SectionArticle },
  carousel: { component: SectionCarousel },
  columns: { component: SectionColumns },
  features: { component: SectionFeatures },
  footer: { component: SectionFooter },
  hero: { component: SectionHero },
};

//////////////////////
// Section builder //
//////////////////////

const SectionBuilder = props => {
  const { sections, options, params } = props;
  const { subCategory } = params || {};

  const [tabName, setTabName] = useState(PHONES_ACCESSORIES);
  const [selectedOption, setSelectedOption] = useState('newest');
  const config = useConfiguration();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const routeConfiguration = useRouteConfiguration();
  const state = useSelector(state => state);

  const {
    trendingListingsIds,
    discoverListingResultIds,
    searchParams,
    userData: featuredSellers,
    beforeGoneListingResultIds,
    topEarningUsers,
    recentListingsIds,
    seasonalListingsIds,
  } = state.LandingPage;
  const trendingListings = getListingsById(state, trendingListingsIds);
  const trendingListingsmostWishlisted = trendingListings
    .filter(listing => listing.attributes.publicData.wishlistCount) // Filter listings with wishlistCount
    .sort((a, b) => b.attributes.publicData.wishlistCount - a.attributes.publicData.wishlistCount); // Sort by wishlistCount descending
  const getBeforeItsGoneListings = trendingListings.filter(listing => {
    const publicData = listing.attributes.publicData;

   // Check if it's an auction listing
const isAuction = publicData.listingType === 'auction-product';

// Calculate time remaining (at least 24 hours)
const endDateEpoch = publicData.auctionExpiry;
const endDate = new Date(endDateEpoch); // Convert epoch to Date object
const currentTime = new Date();
const timeDifference = endDate - currentTime;
const has24HoursRemaining = timeDifference >= 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Return listings that meet all conditions (auctions with at least 24 hours remaining)
return isAuction && has24HoursRemaining;
});

const toplifetimeEarningUsers = topEarningUsers
  .filter(listing => listing?.attributes?.profile.publicData?.rewardsWallet != null)
  .sort(
    (a, b) =>
      b.attributes.profile.publicData.rewardsWallet -
      a.attributes.profile.publicData.rewardsWallet
  );

const featuredSellersUsers = featuredSellers
  .filter(seller => seller?.totalListings != null)
  .sort((a, b) => b.totalListings - a.totalListings);

const recentListings = getListingsById(state, recentListingsIds);
console.log({recentListings});
const discoverListings = getListingsById(state, discoverListingResultIds);
const beforeGoneListings = getListingsById(state, beforeGoneListingResultIds);

const electronicsListings = beforeGoneListings.filter(
  item => item.attributes.publicData.category === 'electronics'
);

// Filter for GPUs and Graphics Cards, optionally include NVIDIA and AMD
const filteredListingsGpu = electronicsListings.filter(listing => {
  // Convert the product title to lowercase for consistent filtering
  const title = listing.attributes.title.toLowerCase();

  // Check if the title contains "graphics card" or "gpu"
  const hasGpuOrGraphicsCard = title.includes('graphics card') || title.includes('gpu');

  // Optionally include NVIDIA and AMD in the filter
  const isNvidiaOrAmd = title.includes('nvidia') || title.includes('amd');

  // Return listings that either contain "Graphics Cards", "GPUs", or optionally "NVIDIA" and "AMD"
  return hasGpuOrGraphicsCard || isNvidiaOrAmd;
});

const filteredListingsgamingconsoles = beforeGoneListings.filter(item => {
  const publicData = item.attributes.publicData;

  // Filter for "Video Games Consoles" with at least 1 wishlist add
  return publicData.subCategory === 'video-games-consoles' && publicData.wishlistCount >= 1;
});

const filteredListingsHotPhones = electronicsListings.filter(listing => {
  const category = listing.attributes.publicData.subCategory;
  const wishlistAddCount = listing.attributes.publicData.wishlistCount;
  const price = listing.attributes.price.amount;

  // Check if the category is 'Phones & Accessories', wishlist count is at least 1, and price is £500 or more
  return category === 'phones-accessories' && wishlistAddCount >= 1 && price >= 50000; // GBP amount in pence
});

const seasonalListings = getListingsById(state, seasonalListingsIds);
const otherListings = beforeGoneListings?.length > 0 &&
  beforeGoneListings.filter(e => !e?.attributes?.publicData?.beforeItsGone);

const { sectionComponents = {}, isInsideContainer, ...otherOption } = options || {};

// If there's no sections, we can't render the correct section component
if (!sections || sections.length === 0) {
  return null;
}
  const handleSortOptionChange = event => {
    setSelectedOption(event.target.value);
  };
  // Selection of Section components
  const components = { ...defaultSectionComponents, ...sectionComponents };
  const getComponent = sectionType => {
    const config = components[sectionType];
    return config?.component;
  };

  // Generate unique ids for sections if operator has managed to create duplicates
  // E.g. "foobar", "foobar1", and "foobar2"
  const sectionIds = [];
  const getUniqueSectionId = (sectionId, index) => {
    const candidate = sectionId || `section-${index + 1}`;
    if (sectionIds.includes(candidate)) {
      let sequentialCandidate = `${candidate}1`;
      for (let i = 2; sectionIds.includes(sequentialCandidate); i++) {
        sequentialCandidate = `${candidate}${i}`;
      }
      return getUniqueSectionId(sequentialCandidate, index);
    } else {
      sectionIds.push(candidate);
      return candidate;
    }
  };
  const isMapVariant = false;
  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };
  const listingFieldsConfig = listingFields || [];
  const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
  const { searchParamsInURL } = searchParamsPicker(
    location.search,
    searchParams,
    listingFieldsConfig,
    defaultFiltersConfig,
    sortConfig,
    isOriginInUse(config)
  );

  const validQueryParams = validFilterParams(
    searchParamsInURL,
    listingFieldsConfig,
    defaultFiltersConfig,
    false
  );
  // Selected aka active filters
  const selectedFilters = validFilterParams(
    validQueryParams,
    listingFieldsConfig,
    defaultFiltersConfig
  );
  const handleSortBy = (urlParam, values) => {
    const urlQueryParams = validUrlQueryParamsFromProps({ location, config });

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    if (subCategory) {
      history.push(
        createResourceLocatorString(
          'LandingPageListing',
          routeConfiguration,
          { subCategory },
          queryParams
        )
      );
    } else {
      history.push(createResourceLocatorString('LandingPage', routeConfiguration, {}, queryParams));
    }
  };
  const conflictingFilterActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    validQueryParams,
    listingFieldsConfig,
    defaultFiltersConfig
  );
  const sortBy = mode => {
    return sortConfig.active ? (
      <SortBy
        sort={validQueryParams[sortConfig.queryParamName]}
        isConflictingFilterActive={!!conflictingFilterActive}
        hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
        selectedFilters={selectedFilters}
        onSelect={handleSortBy}
        showAsPopup
        mode={mode}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;
  };

  if (selectedOption === 'newest') {
    discoverListings.sort(
      (a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
    );
  } else if (selectedOption === 'price') {
    discoverListings.sort(
      (a, b) => a.attributes.price?.amount / 100 - b.attributes.price?.amount / 100
    );
  } else if (selectedOption === '-price') {
    discoverListings.sort(
      (a, b) => b.attributes.price?.amount / 100 - a.attributes.price?.amount / 100
    );
  }

  const onTabChange = tabName => {
    setTabName(prevTabName => {
      dispatch(fetchDiscoverListings({ tabName }, config));
      return tabName;
    });
  };

  const SeeAllButton = ({ param = {} }) => {
    return (
      <span
        className={css.seeAllText}
        onClick={() => {
          history.push(
            createResourceLocatorString('SearchPage', routeConfiguration, {}, { ...param })
          );
        }}
      >
        <FormattedMessage id="LandingPage.seeAll" />
      </span>
    );
  };

  const seenOnSocialMediaSection = sections?.find(
    section => section.sectionName === SEEN_ON_SOCIAL_MEDIA
  );
  const filteredSections = sections?.filter(
    section =>
      section.sectionName !== SEEN_ON_SOCIAL_MEDIA &&
      section.sectionName !== 'games' &&
      section.sectionName !== 'calendar'
  );
  const gamesSection = sections?.find(section => section.sectionName === 'games');
  const calendarSection = sections?.find(section => section.sectionName === 'calendar');

  return (
    <>
      {filteredSections.map((section, index) => {
        const Section = getComponent(section.sectionType);
        // If the default "dark" theme should be applied (when text color is white).
        // By default, this information is stored to customAppearance field
        const isDarkTheme =
          section?.appearance?.fieldType === 'customAppearance' &&
          section?.appearance?.textColor === 'white';
        const classes = classNames({ [css.darkTheme]: isDarkTheme });
        const sectionId = getUniqueSectionId(section.sectionId, index);

        if (Section) {
          return (
            <div key={`${sectionId}_i${index}`}>
              <Section
                key={`${sectionId}_i${index}`}
                className={classes}
                defaultClasses={DEFAULT_CLASSES}
                isInsideContainer={isInsideContainer}
                options={otherOption}
                {...section}
                sectionId={sectionId}
              />
              {sectionId == 'home-hero' && (
                <>
                  {/* Seasonal Listings Row */}
                  <div
                    className={classNames(css.sectionPopular, css.featuredSellers, css.blurShadow)}
                  >
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.seasonalHeading" />
                      </h2>
                      {/* <SeeAllButton param={{ pub_trendingListing: true }} /> */}
                    </div>
                    {filteredListingsGpu?.length > 0 && (
                      <TrendingListing
                        trendingListings={filteredListingsGpu}
                        setActiveListing={setActiveListing}
                        dispatch={dispatch}
                        cardRenderSizes={cardRenderSizes}
                        isMapVariant={isMapVariant}
                      />
                    )}
                  </div>

                  {/* Seasonal Listings Row */}
                  <div className={classNames(css.sectionPopular, css.featuredSellers)}>
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>Hot Phones</h2>
                      {/* <SeeAllButton param={{ pub_trendingListing: true }} /> */}
                    </div>
                    {filteredListingsHotPhones?.length > 0 && (
                      <TrendingListing
                        trendingListings={filteredListingsHotPhones}
                        setActiveListing={setActiveListing}
                        dispatch={dispatch}
                        cardRenderSizes={cardRenderSizes}
                        isMapVariant={isMapVariant}
                      />
                    )}
                  </div>
                  {/* Get It Before Gone Listings */}
                  <div className={classNames(css.sectionPopular, css.featuredSellers)}>
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.TopElectronics" />
                      </h2>
                      {/* <SeeAllButton
                        param={{ pub_businessListingUnavailable: false, category: 'Electronics' }}
                      /> */}
                    </div>
                    <GetItBeforeGoneListings
                      listings={electronicsListings}
                      renderSizes={cardRenderSizes(isMapVariant)}
                      setActiveListing={setActiveListing}
                    />
                  </div>
                  {/* Trending Listings Row */}
                  <div className={css.sectionTrending}>
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.trendingHeading" />
                      </h2>
                      {/* <SeeAllButton param={{ pub_trendingListing: true }} /> */}
                    </div>
                    {trendingListingsmostWishlisted?.length > 0 && (
                      <TrendingListing
                        trendingListings={trendingListingsmostWishlisted}
                        setActiveListing={setActiveListing}
                        dispatch={dispatch}
                        cardRenderSizes={cardRenderSizes}
                        isMapVariant={isMapVariant}
                      />
                    )}
                  </div>
                  <div className={css.sectionTrending}>
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.beforeGone" />
                      </h2>
                      {/* <SeeAllButton param={{ pub_businessListingUnavailable: false }} /> */}
                    </div>
                    <GetItBeforeGoneListings
                      listings={getBeforeItsGoneListings}
                      renderSizes={cardRenderSizes(isMapVariant)}
                      setActiveListing={setActiveListing}
                    />
                  </div>
                  {filteredListingsgamingconsoles?.length > 0 && (
                    <div className={css.sectionTrending}>
                      <div className={css.sectionHeading}>
                        <h2 className={css.sectionTitle}>
                          <FormattedMessage id="LandingPage.TrendinginGaming" />
                        </h2>
                        {/* <SeeAllButton param={{ pub_businessListingUnavailable: false }} /> */}
                      </div>
                      <GetItBeforeGoneListings
                        listings={filteredListingsgamingconsoles}
                        renderSizes={cardRenderSizes(isMapVariant)}
                        setActiveListing={setActiveListing}
                      />
                    </div>
                  )}
                  {/* Popular Categories Row */}
                  <div className={classNames(css.sectionTrending, css.sectionPopular)}>
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.popularHeading" />
                      </h2>
                      {/* <SeeAllButton /> */}
                    </div>
                    <PopularCategories popularCategories={popularCategories} history={history} />
                  </div>
                  {/* Games Row */}
                  <GamesSection gamesSection={gamesSection} />
                  {/* Explore Now Banner */}

                  {/* <section className={css.SectionBanner}>
                    <img src={banner1} alt="banner1" />
                    <div className={css.bannerInfo}>
                      <div>
                        <h4>USE CODE: XXXWATCHES</h4>
                        <h2>New Deals</h2>
                      </div>
                      <Button onClick={() => history.push('/s')}>
                        Explore Now
                      </Button>
                    </div>
                  </section> */}
                  {/*Top Earner Row */}
                  <div className={classNames(css.featuredSellers)}>
                    <div className={css.sectionHeading} style={{ padding: 0 }}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.topEarnerUser" />
                      </h2>
                    </div>
                    <div className={css.featuredSellerList}>
                      {toplifetimeEarningUsers?.map((user, index) => {
                        return (
                          <React.Fragment key={index}>
                            <UserCard
                              user={user}
                              index={index}
                              isTopEarnerCard={true}
                              history={history}
                            />
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  {/* Calender Row */}
                  {/* <CalenderSection calendarSection={calendarSection}/> */}
                  {/* Recently Added Row */}
                  <div className={classNames(css.sectionTrending)}>
                    <div className={css.sectionHeading}>
                      <h2 className={css.sectionTitle}>Recently Added</h2>
                      {/* <SeeAllButton /> */}
                    </div>
                    {recentListings?.length > 0 && (
                      <RecentlyAddedListings
                        recentListings={recentListings}
                        setActiveListing={setActiveListing}
                        dispatch={dispatch}
                        cardRenderSizes={cardRenderSizes}
                        isMapVariant={isMapVariant}
                      />
                    )}
                  </div>
                  {/* Discover More Row */}
                  <div className={css.sectionDiscover}>
                    <h2 className={css.sectionTitle}>
                      <FormattedMessage id="LandingPage.discoverMore" />
                    </h2>
                    <div className={css.filterWrappers}>
                      <div className={css.filterCategory}>
                        {popularSubCategories?.map((type, id) => {
                          return (
                            <div
                              className={classNames(
                                css.filters,
                                tabName === type.key && css.selected
                              )}
                              key={id}
                              onClick={e => {
                                onTabChange(type.key);
                              }}
                            >
                              {type.label}
                            </div>
                          );
                        })}
                      </div>
                      <div className={css.selectFields}>
                        <select onChange={event => handleSortOptionChange(event)}>
                          <option value="newest">
                            {selectedOption === 'newest' && 'Sort By : '}New Listing
                          </option>
                          <option value="-price">
                            {selectedOption === '-price' && 'Sort By : '}High To Low{' '}
                          </option>
                          <option value="price">
                            {selectedOption === 'price' && 'Sort By : '}Low To High{' '}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      {discoverListings?.length > 0 ? (
                        <div className={css.listingCards}>
                          {discoverListings?.slice(0, 8).map(list => {
                            return (
                              <ListingCard
                                key={list.id.uuid}
                                className={css.listingCard}
                                listing={list}
                                renderSizes={cardRenderSizes(isMapVariant)}
                                setActiveListing={() => dispatch(setActiveListing(list.id))}
                                isDiscoverCard={true}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <p className={css.noListingsText}>No Items...</p>
                      )}
                      {discoverListings?.length > 8 && (
                        <span
                          className={css.seeAllBtnCenter}
                          onClick={() => {
                            history.push(
                              `/s?refinementList=${tabName ? tabName : 'phones-accessories'}`
                            );
                          }}
                        >
                          {`See All ${
                            popularSubCategories.find(item => tabName === item.key)?.label
                          }`}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Shop All Banner */}
                  {/* <section className={css.SectionBanner2}>
                    <img src={banner2} alt="banner1" />
                    <div className={css.bannerInfo}>
                      <h4>USE CODE: XXXWATCHES</h4>
                      <h2>Banner Headline Here</h2>
                      <Button onClick={() => history.push('/s')}>
                        Shop All Watches
                      </Button>
                    </div>
                  </section> */}
                  {/* Seen on Social Media Row */}
                  <SeenOnSocialMediaListings seenOnSocialMediaSection={seenOnSocialMediaSection} />
                  {/* Featured Seller Row */}
                  <div className={css.featuredSellers}>
                    <div className={css.sectionHeading} style={{ padding: 0 }}>
                      <h2 className={css.sectionTitle}>
                        <FormattedMessage id="LandingPage.featuredSellers" />
                      </h2>
                    </div>
                    <div className={css.featuredSellerList}>
                      {featuredSellersUsers?.map((user, index) => {
                        return (
                          <React.Fragment key={index}>
                            <UserCard user={user} index={index} history={history} />
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        } else {
          // If the section type is unknown, the app can't know what to render
          console.warn(
            `Unknown section type (${section.sectionType}) detected using sectionName (${section.sectionName}).`
          );
          return null;
        }
      })}
    </>
  );
};

const propTypeSection = shape({
  sectionId: string,
  sectionName: string,
  sectionType: oneOf(['article', 'carousel', 'columns', 'features', 'hero']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }),
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const defaultSections = shape({
  sections: arrayOf(propTypeSection),
  options: propTypeOption,
});

const customSection = shape({
  sectionId: string.isRequired,
  sectionType: string.isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});
const propTypeOptionForCustomSections = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
  sectionComponents: shape({ component: node }).isRequired,
  // isInsideContainer boolean means that the section is not taking
  // the full viewport width but is run inside some wrapper.
  isInsideContainer: bool,
});

const customSections = shape({
  sections: arrayOf(customSection),
  options: propTypeOptionForCustomSections.isRequired,
});

SectionBuilder.defaultProps = {
  sections: [],
  options: null,
};

SectionBuilder.propTypes = oneOf([defaultSections, customSections]).isRequired;

export default SectionBuilder;
